import React, { useState } from 'react'
import { ActionList, Popover, Button } from '@shopify/polaris'
import { Modal, Select, Progress } from 'antd'
import { retryTrackingCodeCrawl } from '../../../services/api/PublicTrackingServices'


function BulkActionRetryCrawl(props) {
    const { totalCheck, setTotalCheck, setIsCheckAll } = props
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [countSuccess, setCountSuccess] = useState([])
    const [countError, setCountError] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const [popoverActive, setPopoverActive] = useState(false)
    const [processList, setProcessList] = useState([])
    const [loading, setLoading] = useState(false)


    const showModal = () => {
        setIsModalVisible(true)
    }

    const togglePopoverActive = () => {
        setPopoverActive((popoverActive) => !popoverActive)
    }

    const handleOk = async () => {
        setButtonLoading(true)
        let err = {}
        const success = totalCheck.every(item => item)
        await setProcessList([])
        await setCountSuccess([])
        await setCountError([])
        if (success) {
            for (const item of totalCheck) {
                const token = localStorage.getItem('pf126.tracking.accessToken')
                const data = {
                    trackings: [
                        {
                            trackingCode: item.tracking_code,
                            _id: item._id,
                            source: item.source,
                        }
                    ]
                }

                const test = await retryTrackingCodeCrawl(data, token)

                if (test.success === true) {
                    await setCountSuccess(countSuccess => [...countSuccess, test])
                    const Ptest = { ...item, ...test }
                    setProcessList(processList => [...processList, Ptest])
                }
                else {
                    setCountError(countError => [...countError, test])
                    const Ptest = { ...item, ...test }
                    setProcessList(processList => [...processList, Ptest])
                }
            }
            setButtonLoading(false)
        }
        else {
            err.notes = "Please choose items to retry!!!"
        }
        setErrors(err)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setErrors({})
        setProcessList([])
        setTotalCheck()
        setCountSuccess([])
        setCountError([])
        setIsCheckAll()
        setLoading(false)
    }

    const activator = (
        <Button onClick={togglePopoverActive} disclosure>
            Actions
        </Button>
    )

    return (
        <div className='BulkActionRetryCrawl'>
            <div style={{ height: 'auto' }}>
                <Popover
                    active={popoverActive}
                    activator={activator}
                    autofocusTarget="first-node"
                    onClose={togglePopoverActive}
                >
                    <Popover.Pane>
                        <ActionList
                            actionRole="menuitem"
                            items={[
                                { content: <a onClick={showModal}>Retry Crawler</a> },
                            ]}
                        />
                    </Popover.Pane>
                </Popover>
            </div>
            <Modal
                title="Retry crawler"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Retry crawler"
                confirmLoading={buttonLoading}
            >
                <div className='CheckedList'>
                    <h3>List processing: <b>{totalCheck.length}</b> items</h3>
                    {processList.length !== 0 &&
                        <b>
                            <span>
                                Success: <span style={{ color: "green" }} >{countSuccess.length}</span> items,
                                Error: <span style={{ color: "red" }}>{countError.length}</span> items
                            </span>
                        </b>
                    }
                    <div className='ListItem'>
                        <ul>
                            {processList.length === 0 &&
                                totalCheck.map((item, index) => (
                                    <li key={index}>
                                        <span>
                                            {item.tracking_code}
                                        </span>
                                        <Progress
                                            percent={100}
                                            status="active"
                                            showInfo={false}
                                        />
                                    </li>
                                ))
                            }
                            {processList.length !== 0 &&
                                processList.map((item, index) => (
                                    <li key={index}>
                                        <span>
                                            {item.tracking_code}

                                            {item.success === false &&
                                                <small className='Error'>
                                                    {item.message}
                                                </small>
                                            }
                                            {item.success === true &&
                                                <small className='Success'>
                                                    Success
                                                </small>
                                            }
                                        </span>
                                        <Progress
                                            percent={100}
                                            status={item.success === true ? "success" : "exception"}
                                            showInfo={true}
                                        />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default BulkActionRetryCrawl