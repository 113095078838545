import createAPIServices from './createApiServices'

const baseUrl = process.env.REACT_APP_PUBLIC_URL_TRACKING || 'https://staging-tracking-v2.merchize.com/crawler/api'
const api = createAPIServices({baseUrl})

export const getListJob = ({page, limit, source}) => {
    return api.makeBasicAuth({
        url: '/job/list',
        method: 'GET',
        params: {
            page, limit, source
        }
    })
}

export const forceJob = (data) => {
    return api.makeBasicAuth({
        url: '/job/run',
        method: 'POST',
        data: data
    })
}

export const getListProxy = ({page, limit, active}) => {
    return api.makeBasicAuth({
        url: '/proxy/list',
        method: 'GET',
        params: {
            page, limit, active
        }
    })
}

export const syncListProxyFromPartner = (data) => {
    return api.makeBasicAuth({
        url: '/proxy/sync',
        method: 'POST',
        data: data
    })
}

export const toggleStatusProxy = (data) => {
    return api.makeBasicAuth({
        url: '/proxy/active',
        method: 'POST',
        data: data
    })
}

export const getListBandWidth = ({from, to}) => {
    return api.makeBasicAuth({
        url: '/proxy/bw',
        method: 'GET',
        params: {
            from, to
        }
    })
}

export const getCost = ({from, to}) => {
    return api.makeBasicAuth({
        url: '/proxy/cost',
        method: 'GET',
        params: {
            from, to
        }
    })
}

export const getTrackingCodeCrawl = (params) => {
    return api.makeBasicAuth({
        url: '/trackings/error',
        method: 'GET',
        params:params
    })
}

export const retryTrackingCodeCrawl = (params, token) => {
    return api.makeBasicAuth({
        headers:{
            'Authorization': `Bearer ${token}`
        },
        url: '/trackings/retry',
        method: 'POST',
        data:params
    })
}
