import React, {Component} from 'react'
import TrackingCodeCrawl from './TrackingCodeCrawl'
import {Page} from '@shopify/polaris'

class TrackingCodeCrawlContainer extends Component {
    render() {
        return (
            <Page fullWidth title="Tracking Code Crawl">
                <TrackingCodeCrawl {...this.props}/>
            </Page>
        )
    }
}

export default TrackingCodeCrawlContainer
