import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RefreshMajorMonotone } from "@shopify/polaris-icons"
import { retryTrackingCodeCrawl } from '../../../services/api/PublicTrackingServices'
import { Button, Toast } from "@shopify/polaris"

class RetryCrawl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            active: false,
            success: false,
            disabled: false,
        }
    }

    _onToggleActive = () => {
        this.setState({
            active: false
        })
    }

    _RetryCrawl = async () => {
        this.setState({
            loading: true,
            error: '',
            active: false,
            success: false
        })
        const { trackingNumber, source, id } = this.props
        const token = localStorage.getItem('pf126.tracking.accessToken')
        const payload = {
            trackings: [
                {
                    trackingCode: trackingNumber,
                    _id: id,
                    source: source
                }
            ]

        }

        try {
            const response = await retryTrackingCodeCrawl(payload, token)

            const { success, message } = response

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                    success: true,
                    active: true
                })
            }

            this.setState({
                loading: false,
                success: true,
                active: true,
                disabled: true
            }, () => {
                const { reload } = this.props

                if (typeof reload === 'function') {
                    // reload()
                }
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    render() {
        const { loading, error, active, success, disabled } = this.state
        const toastMarkup = active ?
            (!!error && error.length > 0 ? <Toast content={error} onDismiss={this._onToggleActive} error={true} />
                :
                <Toast content="Retry crawler request success!" onDismiss={this._onToggleActive} error={false} />) : null

        return (
            <div className="RetryCrawl">
                <Button destructive onClick={this._RetryCrawl} icon={RefreshMajorMonotone} disabled={loading || disabled}>
                    Retry crawl
                </Button>
                {success && toastMarkup}
            </div>
        )
    }
}

RetryCrawl.propTypes = {
    trackingNumber: PropTypes.string,
    carrier: PropTypes.string
}

export default RetryCrawl
