const sources = [
    {  
        'value': 'dhl', 
        'label': 'DHLeCommerce'
    },
    {
        'value': 'ups',
        'label': 'UPS',
    },
    {
        'value': 'usps',
        'label': 'USPS',
    },
]

export default sources
