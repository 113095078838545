const statuses = [
    {'value': 'created', 'label': 'Created'},
    {'value': 'unknown', 'label': 'Unknown'},
    {'value': 'pre_transit', 'label': 'Pre transit'},
    {'value': 'in_transit', 'label': 'In transit'},
    {'value': 'out_for_delivery', 'label': 'Out for delivery'},
    {'value': 'delivered', 'label': 'Delivered'},
    {'value': 'available_for_pickup', 'label': 'Available for pickup'},
    {'value': 'return_to_sender', 'label': 'Return to sender'},
    {'value': 'failure', 'label': 'Failure'},
    {'value': 'cancelled', 'label': 'Cancelled'}, {'value': 'error', 'label': 'Error'},
]

export default statuses
