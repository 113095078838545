import React, {Component} from 'react'
import { getTrackingCodeCrawl } from '../../../services/api/PublicTrackingServices'
import TrackingCodeCrawlTable from './TrackingCodeCrawlTable'
import {Card} from '@shopify/polaris'
import TableFilter from './TableFilter'
import Pagination from '../../shared/Pagination'
import Toast from '../../dashboard/toast'
import _ from 'lodash'

class TrackingCodeCrawl extends Component {
    timeoutLoading = null

    state = {
        query: {
            source: '',
            trackingCode: '',
            transit_time: null,
        },
        paging: {
            limit: 10,
            page: 1,
        },
        fetching: {
            loading: false,
            err: null,
        },
        trackingCodeCrawls: {
            data: [],
            total: 0,
        },
        forceUpdateStatusLoading: false,
        importCsv: false,
        error: false,
        message: '',
        isCheckAll: false,
        totalCheck: [],
    }

    componentDidMount() {
        this.fetchTrackingCodeCrawl()
    }

    handleSelectAll = (e) => {
        const {isCheckAll, trackingCodeCrawls}= this.state
        const totalCheck = trackingCodeCrawls.data.map((li) => li)
        this.setState({
            isCheckAll: !isCheckAll,
            totalCheck: totalCheck
        })
        if (isCheckAll) {
          this.setState({totalCheck:[]})
        }

    }

    handleCheck = (e) => {
        const {totalCheck}= this.state
        const { data, checked } = e.target
        this.setState({totalCheck:[...totalCheck, data]})
        if (!checked) {
            this.setState({totalCheck: totalCheck.filter((item) => item !== data)})
        }
    }

    setQuery = (filter, delayLoading = false) => this.setState(({query, paging}) => ({
        query: {
            ...query, ...filter,
        },
        paging: {...paging, page: 1},
    }), () => {
        if (!delayLoading) return this.fetchTrackingCodeCrawl()

        if (this.timeoutLoading) clearTimeout(this.timeoutLoading)
        this.timeoutLoading = setTimeout(async () => await this.fetchTrackingCodeCrawl(), 1000)
    })

    setFetching = (loading, err = null) => {
        const {trackingCodeCrawls} = this.state
        this.setState({
            fetching: {loading, err},
            trackingCodeCrawls: {
                ...trackingCodeCrawls,
                ...loading ? {data: []} : {},
            },
        })
    }

    submitQuery = () => {
        const {paging} = this.state
        this.setState({paging: {...paging, page: 1}}, () => this.fetchTrackingCodeCrawl())
    }

    switchPage = page => () => {
        const {paging, trackingCodeCrawls: {data}, query} = this.state
        const lastId = _.get(_.last(data), 'id', '') || ''

        this.setState({
            paging: {...paging, page},
            query: {...query, last_id: lastId}
        }, () => this.fetchTrackingCodeCrawl())
    }

    fetchTrackingCodeCrawl = async () => {
        const {query, paging, fetching} = this.state
        this.setState({
            totalCheck: [],
            isCheckAll: false,
        })

        if (fetching.loading) return
        this.setFetching(true)

        try {
            const payload ={
                ...query, ...paging,
            }
            const {success, data, message} = await getTrackingCodeCrawl(payload)
            this.setFetching(false, message)
            if (!success) {
                return this.setState({
                    error: true,
                    message,
                })
            }

            if (data.lastPage || data.data.length < 10) {
                this.setState({lastPage: data.lastPage})
            }
            this.setState({trackingCodeCrawls: {data: data.data}})
        } catch (e) {
            this.setFetching(false, e.message)
            return this.setState({
                error: true,
                message: e.message,
            })
        }
    }


    render() {
        const {trackingCodeCrawls, query, fetching, paging, error, message, lastPage, isCheckAll, totalCheck} = this.state
        const {data} = trackingCodeCrawls
        const {loading} = fetching

        return (
            <div className="TrackingCodeCrawl">
                <div className="Toolbar">
                    {error && (<Toast message={message} error={error} />)}
                </div>

                <Card sectioned>
                    <Card.Subsection>
                        <TableFilter filter={query} setFilter={this.setQuery} loading={loading} />
                    </Card.Subsection>
                    <Card.Subsection>
                        <TrackingCodeCrawlTable
                            data={data} loading={loading}
                            filter={query} setFilter={this.setQuery}
                            submitFilter={this.submitQuery} page={paging.page} limit={paging.limit}
                            fetchEasyPostInformation={this.fetchEasyPostInformation}
                            reload={this.fetchTrackingCodeCrawl}
                            isCheckAll={isCheckAll}
                            handleSelectAll={this.handleSelectAll}
                            onCheck={this.handleCheck}
                            totalCheck={totalCheck}
                            setTotalCheck={()=>this.setState({totalCheck:[]})}
                            setIsCheckAll={()=>this.setState({isCheckAll:false})}
                        />
                    </Card.Subsection>
                    <Card.Subsection>
                        <Pagination showQuickJumper onSwitchPage={this.switchPage} current={paging.page} amount={lastPage} />
                    </Card.Subsection>
                </Card>
            </div>
        )
    }
}


export default TrackingCodeCrawl
