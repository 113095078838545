import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataTable } from '@shopify/polaris'
import humanizeTime from '../../../helpers/humanizeTime'
import statuses from '../static/statuses'
import moment from 'moment'
import RetryCrawl from "./RetryCrawl"
import { Checkbox } from 'antd'
import BulkActionRetryCrawl from './BulkActionRetryCrawl'




class TrackingCodeCrawlTable extends Component {
    state = {
        loading: {
            getEasyPost: '',
        },
    }

    formatRow = (data, row) => {
        if (Array.isArray(data)) {
            return (data && data.length > 0) ? data[data.length - 1][row] : ''
        }
        return data ? data[row] : ''
    }

    formatDate = (date, format = 'llll') => date ? moment(date).format(format) : ''

    _mapRows = data => {
        const { reload, onCheck, totalCheck } = this.props
        const rows = []

        data.forEach((item, index) => {
            const {
                _id,
                tracking_code,
                carrier,
                type,
                origin_carrier,
                source,
                data,
                crawler_at,
                created_at,
                error_info,
            } = item

            const status = this.formatRow(data, 'status')
            const delivered_at = this.getDelivered(data, type)
            const vNumber = tracking_code

            const parsedStatus = (statuses.find(({ value }) => value === status) || {}).label || status
            const vStatus = <span className={status}>{parsedStatus}</span>
            const vError_info = <span className={error_info}>{error_info}</span>
            const vDeliveredAt = this.formatDate(delivered_at)
            const forceUpdate = <RetryCrawl reload={reload} trackingNumber={vNumber} source={source} id={_id} />
            const row = [
                <Checkbox
                    data={item}
                    checked={totalCheck.includes(item)}
                    onChange={onCheck}
                />,
                vNumber, carrier, origin_carrier, source, vStatus, vError_info,
                humanizeTime(crawler_at || created_at), vDeliveredAt, forceUpdate,
            ]
            // eslint-disable-next-line
            rows.push(row.map(col => {
                if (col !== forceUpdate) {
                    // eslint-disable-next-line
                    return <label>{col}</label>
                } else {
                    return col
                }
            })
            )
        })

        return rows
    }

    getDelivered = (data, type) => {
        if (type === 'trackingmore') {
            const trackings = data ? (data.origin_info || {}) : {}
            const trackingDetails = trackings ? (trackings.trackinfo || []) : []
            const tracking = trackingDetails.find(tracking => tracking.checkpoint_status === 'delivered')

            return tracking ? tracking.Date : ''
        } else {
            return this.formatRow(data, 'delivered_at')
        }
    }

    render() {
        const { data, page, limit, isCheckAll, handleSelectAll, totalCheck, setTotalCheck, setIsCheckAll } = this.props
        const rows = this._mapRows(data)
        const footer = `Showing ${(page - 1) * limit + 1} - ${data.length}`
        const cols = !!totalCheck.length ?
            [
                <Checkbox
                    checked={isCheckAll}
                    onChange={handleSelectAll}
                />,
                <BulkActionRetryCrawl
                    totalCheck={totalCheck||[]}
                    setTotalCheck={setTotalCheck}
                    setIsCheckAll={setIsCheckAll}
                />,
                '', '', '', '', '', '', '', '',
            ] :
            [
                <Checkbox
                    checked={isCheckAll}
                    onChange={handleSelectAll}
                />,
                'Tracking Number',
                'Carrier',
                'Origin Carrier',
                'Source',
                'Status',
                'Error info',
                'Last update at',
                'Delivered at',
                'Actions'
            ]

        const columnContentTypes = [
            'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text',
        ]

        const tableCol = (cols) => {
            return cols.map(col => <p className='font-bold'>{col}</p>)
        }

        return (
            <DataTable
                columnContentTypes={columnContentTypes}
                headings={tableCol(cols)}
                rows={rows}
                footerContent={footer}
            />
        )
    }
}

TrackingCodeCrawlTable.propTypes = {
    total: PropTypes.number,
    data: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    submitFilter: PropTypes.func.isRequired,
    displayDetail: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    showDetail: PropTypes.object.isRequired,
}

export default TrackingCodeCrawlTable
